
import { defineComponent, InputHTMLAttributes } from 'vue'
import Button from "@/components/Button.vue"
import Toast from "@/components/Toast.vue"

// Utils
import utilities from "../utilities";

export default defineComponent({
    name: "Login",
    components:{
        Button,
        Toast
    },
    data(){
        return{
            section: 'login',
            sectionTitle: '',
            sectionText: '',
            sectionButton: '',
            changeSection: false,
            submitting: false,
            passShowStatus: false,
            toastStatus: false,
            accountButtons:[
                {
                    icon: 'user-plus-solid.svg',
                    text: 'Crear Cuenta',
                    action: 'create'
                },
                {
                    icon: 'user-lock-solid.svg',
                    text: 'Olvidé mi contraseña',
                    action: 'recover'
                }
            ]
        }
    },
    mounted(){
            //logout
            if(this.$route.query.action == 'logout'){
                localStorage.removeItem('token')
                setTimeout( ()=>{
                    this.$router.push('/')
                }, 1000)
            }

            //restore password
            if(this.$route.query.token){
                this.section = 'restore'
            }

            // set init section
            this.setSection(this.section)
    },
    methods:{
        setSection: function(section:string){

            this.changeSection = true

            setTimeout(()=>{
                this.section= section

                if(this.section == 'login'){
                    this.sectionTitle = '¡Qué bueno volverte a ver por acá!'
                    this.sectionText = 'Ingresá a tu cuenta para seguir disfrutando de Reprocard'
                    this.sectionButton = 'Iniciar Sesión'
                }

                if(this.section == 'recover'){
                    this.sectionTitle = '¡No te preocupes!'
                    this.sectionText = 'Ingresá tu correo y te enviaremos un link para restablecer tu contraseña'
                    this.sectionButton = 'Enviar'
                }

                if(this.section == 'recover-sent'){
                    this.sectionTitle = '¡Listo!'
                    this.sectionText = 'Si estás registrado recibirás un email para reestablecer tu contraseña'
                }

                if(this.section == 'create'){
                    this.sectionTitle = '¡Sé parte de algo grande!'
                    this.sectionText = 'Unite a Reprocard ingresando tus datos'
                    this.sectionButton = 'Registrarme'
                }

                if(this.section == 'welcome'){
                    this.sectionTitle = '¡Ya sos parte de Reprocard!'
                    this.sectionText = 'Ingresando tus datos podes loguearte'
                }

                if(this.section == 'restore'){
                    this.sectionTitle = 'Restablecé tu contraseña'
                    this.sectionText = 'Ingresá tu nueva clave y recuperá el acceso a tu cuenta'
                    this.sectionButton = 'Restablecer'
                }
            }, 300)

            setTimeout(()=>{
                this.changeSection = false
            }, 600)
        },
        showPass: function(){
            const passInput = this.$refs.password as any
            if( this.passShowStatus === false ){
                this.passShowStatus= true
                passInput.type= 'string'
            }else{
                this.passShowStatus= false
                passInput.type= 'password'
            }
        },
        submit: async function(){
            
            this.submitting = true

            const form = this.$refs.form as FormData

            const formCheck = utilities.checkForm(form)
        
            if( !formCheck ){
                (this.$refs.toast as any).show('Hay datos incompletos')
                this.submitting = false
            }else{
                // login
                if(this.section == 'login'){

                    const email:string = (this.$refs.email as InputHTMLAttributes).value
                    const password:string = (this.$refs.password as InputHTMLAttributes).value
                    
                    utilities.auth( email, password, (msg:string)=>{
                        (this.$refs.toast as any).show(msg)
                        this.submitting = false
                    }, (this.$route.query['to']) ? this.$route.query['to'] as string : '' )
                    
                }

                // recover password
                if(this.section == 'recover'){

                    const email:string = (this.$refs.email as InputHTMLAttributes).value
                    
                    utilities.resetPassword(email)
                    
                    this.setSection('recover-sent')

                    this.submitting = false
                    
                }

                // create account
                if(this.section == 'create'){
                    const firstName:string = (this.$refs.first_name as InputHTMLAttributes).value
                    const lastName:string = (this.$refs.last_name as InputHTMLAttributes).value
                    const email:string = (this.$refs.email as InputHTMLAttributes).value
                    const password:string = (this.$refs.password as InputHTMLAttributes).value

                    const response = await utilities.createAccount(firstName, lastName, email, password, (msg:string)=>{
                        (this.$refs.toast as any).show(msg)
                        this.submitting = false
                    })

                     if(response == 'ok'){
                        this.setSection('welcome')
                        this.submitting = false
                    }else{
                       (this.$refs.toast as any).show('Hubo un error. Intente nuevamente.') 
                    }
                }

                // recover password
                if(this.section == 'restore'){
                    const password:string = (this.$refs.password as InputHTMLAttributes).value

                    const response = await utilities.setPassword(password, this.$route.query.token as string)

                     if(response == 'ok'){
                        this.setSection('welcome')
                        this.submitting = false
                    }else{
                       (this.$refs.toast as any).show('Hubo un error. Intente nuevamente.') 
                    }
                }
            }
            
        },
        openToast: function (){
            this.toastStatus = true
        }
    }
})
